import React from "react";
import Layout from "../layouts";

const Index = () => {

    return (
        <Layout>
        </Layout>
    )
};

export default Index;
